
import { useState } from 'react';

const tabs = ["Hakkımızda", "Yazılarımız", "İletişim" ];
const tabContent = [ 
      "Sitemiz henüz yapım aşamasındadır." ,
      "Sitemiz henüz yapım aşamasındadır." ,
      "Sitemiz henüz yapım aşamasındadır."    
    ];
const tabColors = ["lightcyan", "#cde7cd", "lightgray", "pink"];

function App() {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabClick = (index) => {
      setSelectedTab(index);
  }
  const styleMain = { '--main-color': tabColors[selectedTab] };

  return (
    <div className="app-main-canvas" style={styleMain}>
      <div className="app-top-bar">
         <img src={"./mimi.jpg"}  style={{maxWidth: "15vh", maxHeight: "15vh", height: "100%", width: "100%", display: "block", marginRight:"5vw"}} /> 
        <p>Avukat Miray Altun</p>
      </div>
      <div className="app-tab-container">
        {tabs.map((tab, index) => {
          const style = { '--tab-color': tabColors[index] , 
          '--bottom-border-color': index===selectedTab ?  "gray" :  tabColors[index]  ,
          '--alter-bottom-border-color': index===selectedTab ?   tabColors[index] : "gray" ,
          'border-bottom': index===selectedTab ? `2px solid ${tabColors[index]}`: "",
          'border-top': index===selectedTab ? `2px solid gray`: `2px solid ${tabColors[index]}`};

          return (
            <div className='app-tab' style={style} key={index} onClick={() => handleTabClick(index)}>
              <span>{tab}</span>
            </div>
          );
        })}
      </div>
      <div className='app-content-container'>
        <span>{tabContent[selectedTab]}</span>

      </div>
    </div>
  );
}

export default App;

